<template>
  <div>
    <navbar title="我的客户"></navbar>
    <div class="box" v-if="feedBackList.length > 0">
      <div class="list" v-for="(item, index) in feedBackList" :key="index">
        <div
          :class="
            item.follow_status == 3
              ? 'avtive1 item'
              : item.follow_status == 1
              ? 'avtive2 item'
              : 'item'
          "
        >
          <div class="title">{{ item.phone }}</div>
          <div class="time">
            <img src="../assets/time.png" />
            <div class="tiems">{{ item.time }}</div>
          </div>
        </div>
      </div>
      <div class="tac">—— 暂无更多 ——</div>
    </div>
    <div class="tac" v-else>—— 暂无客户 ——</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      item: {},
      feedBackList: [],
    };
  },
  methods: {
    ...mapActions(["getbelongTo"]),
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
    }),
  },
  mounted() {
    this.getbelongTo({
      type: [2],
      invitation: this.userinfo.invitation,
      phone: "",
      wx: "",
      status: "",
      page: 1,
      pageSize: "1000",
    }).then((res) => {
      if (res.SuccessCode == 200) {
        res.ResponseBody.data.forEach((v) => {
          v.phone = v.phone.substr(0, 3) + "****" + v.phone.substr(7);
        });

        this.feedBackList = res.ResponseBody.data;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.list {
  padding: 0.1rem;
}

.item {
  background-color: #fff;
  padding: 0.1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.time {
  display: flex;
  margin-top: 0.25rem;
}

.time img {
  margin-top: 0.08rem;
  vertical-align: top;
  width: 0.26rem;
  height: 0.26rem;
}

.tiems {
  font-size: 0.32rem;
  margin-left: 0.15rem;
  color: #cfcfcf;
}

.comment {
  margin-left: 3rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cfcfcf;
}

.noFeedBack {
  text-align: center;
  margin-top: 2rem;
}

.noFeedBack img {
  width: 1.5rem;
  height: 1.5rem;
}

.noFeedBack view {
  color: #666;
}

.avtive1 {
  background: rgb(245, 196, 232) !important;
}
.avtive2 {
  background: #d9f5b9 !important;
}
</style>